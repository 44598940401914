import { DeviceMenuItem } from '@app/models/menu-item';
import { Options } from '@models/configurator-models/form-fields';
import { PermissionsList } from './user-permissions';

export const actionMenuItems: DeviceMenuItem[] = [
  {
    title: 'Replace Device',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
    subMenu: [
      {
        title: 'Current Settings',
        disabled: true,
        permission: PermissionsList.DevicePermissions.UpdateDevice,
      },
      { title: 'New Settings', permission: PermissionsList.DevicePermissions.UpdateDevice },
    ],
  },
  {
    title: 'Duplicate Device',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
  {
    title: 'Remove Device',
    disabled: false,
    permission: PermissionsList.DevicePermissions.DeleteDevice,
  },
  {
    title: 'Export as',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
  {
    title: 'Config options',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
  {
    title: 'Test communication',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
  {
    title: 'Test Control Circuit',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
  {
    title: 'Force on',
    disabled: true,
    permission: PermissionsList.DevicePermissions.UpdateDevice,
  },
];

export const baudRateMenuItems: Options[] = [
  {
    displayText: '300',
    value: 300,
    disabled: false,
  },
  {
    displayText: '600',
    value: 600,
    disabled: false,
  },
  {
    displayText: '1200',
    value: 1200,
    disabled: false,
  },
  {
    displayText: '2400',
    value: 2400,
    disabled: false,
  },
  {
    displayText: '4800',
    value: 4800,
    disabled: false,
  },
  {
    displayText: '9600',
    value: 9600,
    disabled: false,
  },
  {
    displayText: '19200',
    value: 19200,
    disabled: false,
  },
  {
    displayText: '38400',
    value: 38400,
    disabled: false,
  },
  {
    displayText: '57600',
    value: 57600,
    disabled: false,
  },
  {
    displayText: '115200',
    value: 115200,
    disabled: false,
  },
];

export const dataBitsMenuItems: Options[] = [
  {
    displayText: '7',
    value: 7,
    disabled: false,
  },
  {
    displayText: '8',
    value: 8,
    disabled: false,
  },
];

export const stopBitsMenuItems: Options[] = [
  {
    displayText: '1',
    value: 1,
    disabled: false,
  },
  {
    displayText: '2',
    value: 2,
    disabled: false,
  },
];

export const paritiesMenuItems: Options[] = [
  {
    displayText: 'NONE',
    value: 'NONE',
    disabled: false,
  },
  {
    displayText: 'ODD',
    value: 'ODD',
    disabled: false,
  },
  {
    displayText: 'EVEN',
    value: 'EVEN',
    disabled: false,
  },
];
