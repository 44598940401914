import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MenuNode } from '@app/models/table-items';

@Injectable({
  providedIn: 'root',
})
export class TreeMenuService {
  constructor() {}

  getParent(treeControl: FlatTreeControl<MenuNode>, node: MenuNode, parents: string[]): string[] {
    const currentLevel = treeControl.getLevel(node);
    if (currentLevel < 1) return [];
    for (let i = treeControl.dataNodes.indexOf(node) - 1; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];
      if (treeControl.getLevel(currentNode) < currentLevel) {
        parents.push(currentNode.name);
        this.getParent(treeControl, currentNode, parents);
        break;
      }
    }

    return parents;
  }

  closeOtherMenuItems(treeControl: FlatTreeControl<MenuNode>, node: MenuNode) {
    const nodeParents = this.getParent(treeControl, node, []);
    treeControl.dataNodes.forEach(currentNode => {
      if (
        node !== currentNode &&
        !nodeParents.includes(currentNode.name) &&
        treeControl.isExpandable(currentNode) &&
        treeControl.isExpanded(currentNode)
      )
        treeControl.collapse(currentNode);
    });
    return treeControl.dataNodes.indexOf(node);
  }
}
